import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import MultipleChoiceQuestionOptionModel, {
  MultipleChoiceOptionResponse,
} from "./multiple-choice-question-option.model";

export interface MultipleChoiceResponse {
  options: MultipleChoiceOptionResponse[];
  question: string;
}

export default class MultipleChoiceQuestionModel {
  options: MultipleChoiceQuestionOptionModel[];
  question: string;

  constructor(response: MultipleChoiceResponse) {
    this.options = MapResponseToModel(
      response.options,
      MultipleChoiceQuestionOptionModel
    );
    this.question = response.question;
  }
}
