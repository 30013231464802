import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import QuizQuestionOptionModel, {
  QuizQuestionOptionResponse,
} from "./quiz-question-option.model";

export interface QuizQuestionResponse {
  options: QuizQuestionOptionResponse[];
  question: string;
}

export default class QuizQuestionModel {
  options: QuizQuestionOptionModel[];
  question: string;
  cardUid: string;
  cardVersion: number;

  constructor(
    response: QuizQuestionResponse,
    cardUid: string,
    cardVersion: number
  ) {
    this.options = MapResponseToModel(
      response.options,
      QuizQuestionOptionModel
    );
    this.question = response.question;
    this.cardUid = cardUid;
    this.cardVersion = cardVersion;
  }
}
