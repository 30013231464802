import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import SliderQuestionOptionModel, {
  SliderQuestionOptionResponse,
} from "./slider-question-option.model";

export interface SliderQuestionResponse {
  question: string;
  options: SliderQuestionOptionResponse[];
}

export default class SliderQuestionModel {
  question: string;
  options: SliderQuestionOptionModel[];
  cardUid: string;
  cardVersion: number;

  constructor(
    response: SliderQuestionResponse,
    cardUid: string,
    cardVersion: number
  ) {
    this.question = response.question;
    this.options = MapResponseToModel(
      response.options,
      SliderQuestionOptionModel
    );
    this.cardUid = cardUid;
    this.cardVersion = cardVersion;
  }
}
