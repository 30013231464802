import { CardTypesEnum } from "../../enums/card-types.enum";
import MultipleChoiceQuestionModel, {
  MultipleChoiceResponse,
} from "../question/multiple-choice-question.model";
import BaseCardModel, { BaseCardResponse } from "../base-card.model";

export interface MultipleChoiceCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.MULTIPLE_CHOICE;
  data: MultipleChoiceResponse;
}

export default class MultipleChoiceCardModel extends BaseCardModel {
  cardType: CardTypesEnum.MULTIPLE_CHOICE;
  data: MultipleChoiceQuestionModel;

  constructor(multipleChoiceCard: MultipleChoiceCardResponse) {
    super(multipleChoiceCard);
    this.cardType = multipleChoiceCard.cardType;
    this.data = new MultipleChoiceQuestionModel(multipleChoiceCard.data);
  }
}
