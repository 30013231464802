import OpenQuestionModel, {
  OpenQuestionResponse,
} from "../question/open-question.model";
import { CardTypesEnum } from "../../enums/card-types.enum";
import BaseCardModel, { BaseCardResponse } from "../base-card.model";

export interface OpenQuestionCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.QUESTION;
  data: OpenQuestionResponse;
}

export default class OpenQuestionCardModel extends BaseCardModel {
  cardType: CardTypesEnum.QUESTION;
  data: OpenQuestionModel;

  constructor(response: OpenQuestionCardResponse) {
    super(response);
    this.cardType = response.cardType;
    this.data = new OpenQuestionModel(
      response.data,
      response.cardUid,
      response.version
    );
  }
}
