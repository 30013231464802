import React from "react";

interface LoaderProps {
  message?: string;
}

const Loader = ({ message }: LoaderProps) => {
  return (
    <div className="flex flex-1 justify-center items-center flex-col h-screen">
      {message && <p>{message}</p>}
    </div>
  );
};

export default Loader;
