import {
  AudioProvidersEnum,
  EmbedProvidersEnum,
  ImageProvidersEnum,
  MediaTypesEnum,
  VideoProvidersEnum,
} from "../../enums/media-providers.enum";

export interface MediaResponse {
  mediaUid: string;
  provider:
    | ImageProvidersEnum
    | VideoProvidersEnum
    | AudioProvidersEnum
    | EmbedProvidersEnum;
  mediaType: MediaTypesEnum;
  uri: string;
}

export default class MediaModel {
  mediaUid: string;
  provider:
    | ImageProvidersEnum
    | VideoProvidersEnum
    | AudioProvidersEnum
    | EmbedProvidersEnum;
  mediaType: MediaTypesEnum;
  uri: string;

  constructor(media: MediaResponse) {
    this.mediaUid = media.mediaUid;
    this.provider = media.provider;
    this.mediaType = media.mediaType;
    this.uri = media.uri;
  }
}
