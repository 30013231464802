import { ContentStatesEnum } from "../enums/content-states.enum";

export interface BaseContentResponse {
  title: string;
  description: string | null | undefined;
  contentState: ContentStatesEnum;
  sort: number;
  createdAt: string;
  publishedAt?: string;
}

export default class BaseContentModel {
  title: string;
  description?: string;
  contentState: ContentStatesEnum;
  sort: number;
  createdAt: Date;
  publishedAt?: Date;

  constructor(content: BaseContentResponse) {
    this.title = content.title;
    this.description = content.description
      ? content.description !== ""
        ? content.description
        : undefined
      : undefined;
    this.contentState = content.contentState;
    this.sort = content.sort;
    this.createdAt = new Date(content.createdAt);

    if (content.publishedAt) {
      this.publishedAt = new Date(content.publishedAt);
    }
  }
}
