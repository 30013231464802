import BaseContentModel, { BaseContentResponse } from "../base-content.model";
import ContentTypesEnum from "../../enums/content-types.enum";
import CollectionModel, { CollectionResponse } from "./collection.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import { ContentStatesEnum } from "../../enums/content-states.enum";

export interface ChapterResponse extends BaseContentResponse {
  chapterUid: string;
  playbookUid: string;
  userGroupAcl?: string[];
  collections: CollectionResponse[];
}

export default class ChapterModel extends BaseContentModel {
  chapterUid: string;
  playbookUid: string;
  contentType: ContentTypesEnum.CHAPTER;
  userGroupAcl: string[];
  collections: CollectionModel[];

  constructor(chapter: ChapterResponse) {
    super(chapter);
    this.chapterUid = chapter.chapterUid;
    this.playbookUid = chapter.playbookUid;
    this.contentType = ContentTypesEnum.CHAPTER;
    this.userGroupAcl = chapter.userGroupAcl ? chapter.userGroupAcl : [];
    this.collections = MapResponseToModel(
      chapter.collections
        .sort((a, b) => a.sort - b.sort)
        .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED),
      CollectionModel
    );
  }
}
