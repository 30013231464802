import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchQrContent } from "./contentAPI";
import { QrCodeContentModelTypes } from "../../models/qr-code-content.model";

export interface ContentState {
  qrContent?: QrCodeContentModelTypes;
  status: "idle" | "loading" | "failed";
}

const initialState: ContentState = {
  qrContent: undefined,
  status: "idle",
};

export const fetchQrContentAsync = createAsyncThunk(
  "content/fetchContent",
  async ({ baseURL, qrCodeUid }: { baseURL: string; qrCodeUid: string }) => {
    return await fetchQrContent(baseURL, qrCodeUid);
  }
);

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQrContentAsync.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchQrContentAsync.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(
      fetchQrContentAsync.fulfilled,
      (state, action: PayloadAction<QrCodeContentModelTypes>) => {
        state.status = "idle";
        state.qrContent = action.payload;
      }
    );
  },
});

export default contentSlice.reducer;
