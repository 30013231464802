import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import contentReducer from "../features/content/contentSlice";

export const store = configureStore({
  reducer: { content: contentReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
