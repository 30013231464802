import { CardTypesEnum } from "../../enums/card-types.enum";
import BaseCardModel, { BaseCardResponse } from "../base-card.model";

export interface KnowledgeCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.KNOWLEDGE_CARD;
  data: { text: string };
}

export default class KnowledgeCardModel extends BaseCardModel {
  cardType: CardTypesEnum.KNOWLEDGE_CARD;
  data: { text: string };

  constructor(knowledgeCard: KnowledgeCardResponse) {
    super(knowledgeCard);
    this.cardType = knowledgeCard.cardType;
    this.data = knowledgeCard.data;
  }
}
