export interface OpenQuestionResponse {
  question: string;
  explanation: string;
}

export default class OpenQuestionModel {
  question: string;
  explanation: string;
  cardUid: string;
  cardVersion: number;

  constructor(
    response: OpenQuestionResponse,
    cardUid: string,
    cardVersion: number
  ) {
    this.question = response.question;
    this.explanation = response.explanation;
    this.cardUid = cardUid;
    this.cardVersion = cardVersion;
  }
}
