export interface QuizQuestionOptionResponse {
  sort: number;
  content: string;
  isCorrect: boolean;
}

export default class QuizQuestionOptionModel {
  sort: number;
  content: string;
  isCorrect: boolean;

  constructor(response: QuizQuestionOptionResponse) {
    this.sort = response.sort;
    this.content = response.content;
    this.isCorrect = response.isCorrect;
  }
}
