export interface MultipleChoiceOptionResponse {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation: string;
}

export default class MultipleChoiceQuestionOptionModel {
  sort: number;
  content: string;
  isCorrect: boolean;
  explanation: string;

  constructor(response: MultipleChoiceOptionResponse) {
    this.sort = response.sort;
    this.content = response.content;
    this.isCorrect = response.isCorrect;
    this.explanation = response.explanation;
  }
}
