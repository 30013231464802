import { RootState } from "../app/store";
import CollectionModel from "../models/content/collection.model";

export function selectCollectionViaCollectionUid(
  state: RootState,
  collectionUid: string
) {
  const { qrContent } = state.content;
  if (!qrContent) {
    return;
  }

  let collection: CollectionModel | undefined;

  qrContent.playbook.chapters.forEach((chapter) => {
    if (collection) {
      return;
    }
    collection = chapter.collections.find(
      (c) => c.collectionUid === collectionUid
    );
  });

  return collection;
}
