import SliderQuestionModel, {
  SliderQuestionResponse,
} from "../question/slider-question.model";
import { CardTypesEnum } from "../../enums/card-types.enum";
import BaseCardModel, { BaseCardResponse } from "../base-card.model";

export interface SliderCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.SLIDER;
  data: SliderQuestionResponse;
}

export default class SliderCardModel extends BaseCardModel {
  cardType: CardTypesEnum.SLIDER;
  data: SliderQuestionModel;

  constructor(response: SliderCardResponse) {
    super(response);
    this.cardType = response.cardType;
    this.data = new SliderQuestionModel(
      response.data,
      response.cardUid,
      response.version
    );
  }
}
