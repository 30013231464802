import React from "react";
import Media from "../media";
import MediaModel from "../../models/media/media.model";

interface CardProps {
  title: string;
  description?: string;
  media?: MediaModel[];
}

const Card = ({ title, description, media }: CardProps) => {
  return (
    <div
      className="bg-white rounded-lg drop-shadow-2xl"
      style={{ minHeight: 250 }}
    >
      {media && media[0] && (
        <div className="rounded-t-lg overflow-hidden relative">
          <Media media={media[0]} />
        </div>
      )}
      <div className="p-4">
        <h3 className="mb-1">{title}</h3>
        {description ? (
          description.includes("</") ? (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <p>{description}</p>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Card;
