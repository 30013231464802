import { CardTypesEnum } from "../../enums/card-types.enum";
import QuizQuestionModel, {
  QuizQuestionResponse,
} from "../question/quiz-question.model";
import BaseCardModel, { BaseCardResponse } from "../base-card.model";

export interface QuizCardResponse extends BaseCardResponse {
  cardType: CardTypesEnum.QUIZ;
  data: QuizQuestionResponse;
}

export default class QuizCardModel extends BaseCardModel {
  cardType: CardTypesEnum.QUIZ;
  data: QuizQuestionModel;

  constructor(response: QuizCardResponse) {
    super(response);
    this.cardType = response.cardType;
    this.data = new QuizQuestionModel(
      response.data,
      response.cardUid,
      response.version
    );
  }
}
