import BaseContentModel, { BaseContentResponse } from "../base-content.model";
import ContentTypesEnum from "../../enums/content-types.enum";
import CollectionSummaryModel, {
  CollectionSummaryResponse,
} from "./collection-summary.model";
import SummaryTypesEnum from "../../enums/summary-types.enum";
import { CardResponseTypes, CardTypes } from "../../types/card.types";
import SliderCardModel from "../card-types/slider-card.model";
import { CardTypesEnum } from "../../enums/card-types.enum";
import OpenQuestionCardModel from "../card-types/open-question-card.model";
import QuizCardModel from "../card-types/quiz-card.model";
import MultipleChoiceCardModel from "../card-types/multiple-choice-card.model";
import KnowledgeCardModel from "../card-types/knowledge-card.model";
import { ContentStatesEnum } from "../../enums/content-states.enum";

export interface CollectionResponse extends BaseContentResponse {
  collectionUid: string;
  startSummary: CollectionSummaryResponse;
  endSummary: CollectionSummaryResponse;
  chapterUid: string;
  userGroupAcl?: string[];
  cards: CardResponseTypes[];
}

export default class CollectionModel extends BaseContentModel {
  collectionUid: string;
  startSummary: CollectionSummaryModel;
  endSummary: CollectionSummaryModel;
  chapterUid: string;
  contentType: ContentTypesEnum.COLLECTION;
  userGroupAcl: string[];
  cards: CardTypes[];

  constructor(collection: CollectionResponse) {
    super(collection);
    this.collectionUid = collection.collectionUid;
    this.chapterUid = collection.chapterUid;
    this.startSummary = new CollectionSummaryModel(
      collection.startSummary,
      this.collectionUid,
      SummaryTypesEnum.START,
      this.contentState,
      this.chapterUid
    );
    this.endSummary = new CollectionSummaryModel(
      collection.endSummary,
      this.collectionUid,
      SummaryTypesEnum.END,
      this.contentState,
      this.chapterUid
    );
    this.contentType = ContentTypesEnum.COLLECTION;
    this.userGroupAcl = collection.userGroupAcl ? collection.userGroupAcl : [];
    this.cards = collection.cards
      .sort((a, b) => a.sort - b.sort)
      .filter(
        (c) =>
          c.contentState === ContentStatesEnum.PUBLISHED &&
          c.cardType === CardTypesEnum.KNOWLEDGE_CARD
      )
      .map((responseCard) => {
        switch (responseCard.cardType) {
          case CardTypesEnum.MULTIPLE_CHOICE:
            return new MultipleChoiceCardModel(responseCard);
          case CardTypesEnum.KNOWLEDGE_CARD:
            return new KnowledgeCardModel(responseCard);
          case CardTypesEnum.QUESTION:
            return new OpenQuestionCardModel(responseCard);
          case CardTypesEnum.QUIZ:
            return new QuizCardModel(responseCard);
          case CardTypesEnum.SLIDER:
            return new SliderCardModel(responseCard);
          default:
            throw new Error("Card Type not yet supported");
        }
      });
  }
}
