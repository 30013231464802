export interface SourceDataResponse {
  topic: string;
  authorName: string;
}

export interface SourceResponse {
  cardUid: string;
  cardVersion: number;
  sort: number;
  createdAt: Date;
  sourceUid: string;
  text: string;
  url: string;
  data: {
    source: SourceDataResponse[];
  };
}

export default class SourceModel {
  sourceUid: string;
  text: string;
  url: string;

  constructor(source: SourceResponse) {
    this.sourceUid = source.sourceUid;
    this.text = source.text;
    this.url = source.url;
  }
}
