import PlaybookModel, { PlaybookResponse } from "./content/playbook.model";
import ContentTypesEnum from "../enums/content-types.enum";

interface BaseQrCodePlaybookResponse {
  playbook: PlaybookResponse;
}

class BaseQrCodePlaybookModel {
  playbook: PlaybookModel;

  constructor(response: BaseQrCodePlaybookResponse) {
    this.playbook = new PlaybookModel(response.playbook);
  }
}

// Playbook
interface QrCodePlaybookContentResponse extends BaseQrCodePlaybookResponse {
  contentType: ContentTypesEnum.PLAYBOOK;
  data: {
    playbookUid: string;
  };
}

export class QrCodePlaybookContentModel extends BaseQrCodePlaybookModel {
  contentType: ContentTypesEnum.PLAYBOOK;
  data: {
    playbookUid: string;
  };

  constructor(response: QrCodePlaybookContentResponse) {
    super(response);
    this.contentType = ContentTypesEnum.PLAYBOOK;
    this.data = response.data;
  }
}

// Chapter
interface QrCodeChapterContentResponse extends BaseQrCodePlaybookResponse {
  contentType: ContentTypesEnum.CHAPTER;
  data: {
    playbookUid: string;
    chapterUid: string;
  };
}

export class QrCodeChapterContentModel extends BaseQrCodePlaybookModel {
  contentType: ContentTypesEnum.CHAPTER;
  data: {
    playbookUid: string;
    chapterUid: string;
  };

  constructor(response: QrCodeChapterContentResponse) {
    super(response);
    this.contentType = ContentTypesEnum.CHAPTER;
    this.data = response.data;
  }
}

// Collection
export interface QrCodeCollectionContentResponse
  extends BaseQrCodePlaybookResponse {
  contentType: ContentTypesEnum.COLLECTION;
  data: {
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
  };
}

export class QrCodeCollectionContentModel extends BaseQrCodePlaybookModel {
  contentType: ContentTypesEnum.COLLECTION;
  data: {
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
  };

  constructor(response: QrCodeCollectionContentResponse) {
    super(response);
    this.contentType = ContentTypesEnum.COLLECTION;
    this.data = response.data;
  }
}

// Card
export interface QrCodeCardContentResponse extends BaseQrCodePlaybookResponse {
  contentType: ContentTypesEnum.CARD;
  data: {
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
    cardUid: string;
  };
}

export class QrCodeCardContentModel extends BaseQrCodePlaybookModel {
  contentType: ContentTypesEnum.CARD;
  data: {
    playbookUid: string;
    chapterUid: string;
    collectionUid: string;
    cardUid: string;
  };

  constructor(response: QrCodeCardContentResponse) {
    super(response);
    this.contentType = ContentTypesEnum.CARD;
    this.data = response.data;
  }
}

export type QrCodeContentResponseTypes =
  | QrCodePlaybookContentResponse
  | QrCodeChapterContentResponse
  | QrCodeCollectionContentResponse
  | QrCodeCardContentResponse;
export type QrCodeContentModelTypes =
  | QrCodePlaybookContentModel
  | QrCodeChapterContentModel
  | QrCodeCollectionContentModel
  | QrCodeCardContentModel;
