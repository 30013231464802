import MediaModel, { MediaResponse } from "./media.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import BaseContentModel, { BaseContentResponse } from "../base-content.model";

export interface BaseMediaContentResponse extends BaseContentResponse {
  media?: MediaResponse[];
  mediaUids?: string[];
}

export default class BaseMediaContentModel extends BaseContentModel {
  media?: MediaModel[];

  constructor(response: BaseMediaContentResponse) {
    super(response);

    if (response.media && response.media.length > 0) {
      this.media = MapResponseToModel(response.media, MediaModel);
    }
  }
}
