import React from "react";
import PlaybookDetailScreen from "./screens/playbook-detail.screen";
import CollectionDetailScreen from "./screens/collection-detail-screen";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PlaybookDetailScreen />} />
      <Route
        path="/collection/:collectionUid"
        element={<CollectionDetailScreen />}
      />
      <Route
        path="/collection/:collectionUid/card/:cardUid"
        element={<CollectionDetailScreen />}
      />
    </Routes>
  );
}

export default App;
