export interface SliderQuestionOptionResponse {
  sort: number;
  label: string;
  explanation: string;
}

export default class SliderQuestionOptionModel {
  sort: number;
  label: string;
  explanation: string;

  constructor(response: SliderQuestionOptionResponse) {
    this.sort = response.sort;
    this.label = response.label;
    this.explanation = response.explanation;
  }
}
