import { createContext, ReactNode, useContext } from "react";

const BackgroundContext = createContext(
  {} as { setBackgroundColor: (color: string) => void }
);

const BackgroundProvider = ({ children }: { children: ReactNode }) => {
  function setBackgroundColor(color: string) {
    document.getElementsByTagName("body")[0].style.backgroundColor = color;
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", color);
  }

  return (
    <BackgroundContext.Provider value={{ setBackgroundColor }}>
      {children}
    </BackgroundContext.Provider>
  );
};

const useBackground = () => useContext(BackgroundContext);

export { BackgroundProvider, useBackground };
