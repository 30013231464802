import BaseMediaContentModel, {
  BaseMediaContentResponse,
} from "../media/base-media-content.model";
import ContentTypesEnum from "../../enums/content-types.enum";
import { PlaybookCategoryInterface } from "../category.model";
import ChapterModel, { ChapterResponse } from "./chapter.model";
import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import { ContentStatesEnum } from "../../enums/content-states.enum";

export interface PlaybookResponse extends BaseMediaContentResponse {
  playbookUid: string;
  categories: PlaybookCategoryInterface[];
  userGroupAcl?: string[];
  chapters: ChapterResponse[];
}

export default class PlaybookModel extends BaseMediaContentModel {
  playbookUid: string;
  contentType: ContentTypesEnum.PLAYBOOK;
  categories: PlaybookCategoryInterface[];
  userGroupAcl: string[];
  chapters: ChapterModel[];

  constructor(playbook: PlaybookResponse) {
    super(playbook);
    this.playbookUid = playbook.playbookUid;
    this.contentType = ContentTypesEnum.PLAYBOOK;
    this.categories = playbook.categories;
    this.userGroupAcl = playbook.userGroupAcl ? playbook.userGroupAcl : [];
    this.chapters = MapResponseToModel(
      playbook.chapters
        .sort((a, b) => a.sort - b.sort)
        .filter((c) => c.contentState === ContentStatesEnum.PUBLISHED),
      ChapterModel
    );
  }
}
