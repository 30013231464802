import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ContentProvider from "./providers/content.provider";
import { BrowserRouter } from "react-router-dom";
import { BackgroundProvider } from "./providers/background.provider";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";

const container = document.getElementById("root")!;
const root = createRoot(container);

ReactGA.initialize("G-MQ1FEH46TH");

Sentry.init({
  dsn: "https://f25f8ce2a9bf4e6088402fadccdc1568@o307090.ingest.sentry.io/6520369",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BackgroundProvider>
        <ContentProvider>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </ContentProvider>
      </BackgroundProvider>
    </Provider>
  </React.StrictMode>
);

function sendToAnalytics({
  id,
  name,
  value,
}: {
  id: any;
  name: any;
  value: any;
}) {
  ReactGA.ga("send", "event", {
    eventCategory: "Web Vitals",
    eventAction: name,
    eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}

reportWebVitals(sendToAnalytics);
