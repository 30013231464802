import React, { createContext, ReactNode, useEffect, useState } from "react";
import Loader from "../components/loader";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchQrContentAsync } from "../features/content/contentSlice";
import * as Sentry from "@sentry/react";

interface ContentProviderType {
  isError: boolean;
  errorMessage?: string;
}

const ContentContext = createContext({} as ContentProviderType);

interface ContentProviderProps {
  children: ReactNode;
}

enum SearchParams {
  QR_CODE_UID = "q",
  ENVIRONMENT = "e",
}

const ContentProvider = ({ children }: ContentProviderProps) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(
    (state) => state.content.status === "loading"
  );

  function handleError(error: string): void {
    setIsError(true);
    setErrorMessage(error);
    Sentry.captureException(error);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    Object.values(SearchParams).forEach((param) => {
      if (!searchParams.has(param)) {
        handleError(`Can't look up content without (${param}).`);
      }
    });

    const qrCodeUid = searchParams.get(SearchParams.QR_CODE_UID) ?? "";
    const environment = searchParams.get(SearchParams.ENVIRONMENT);
    const baseURL =
      environment === "local"
        ? `http://localhost:3000`
        : `https://api.${environment}.primio.app`;

    dispatch(fetchQrContentAsync({ baseURL, qrCodeUid }));
  }, [dispatch]);

  return (
    <ContentContext.Provider
      value={{
        isError,
        errorMessage,
      }}
    >
      {isFetching ? (
        <Loader />
      ) : isError && errorMessage ? (
        <Loader message={errorMessage} />
      ) : (
        children
      )}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
