import MediaModel, { MediaResponse } from "../media/media.model";
import { ContentStatesEnum } from "../../enums/content-states.enum";
import ContentTypesEnum from "../../enums/content-types.enum";
import { MapResponseToModel } from "../../utils/map-response-to-model.util";
import SummaryTypesEnum from "../../enums/summary-types.enum";

export interface CollectionSummaryResponse {
  title: string;
  description: string;
  media?: MediaResponse[];
  mediaUids?: string[];
}

export default class CollectionSummaryModel {
  title: string;
  description: string;
  collectionUid: string;
  chapterUid: string;
  summaryType: SummaryTypesEnum;
  contentState: ContentStatesEnum;
  contentType: ContentTypesEnum.SUMMARY;
  media?: MediaModel[];

  constructor(
    summary: CollectionSummaryResponse,
    collectionUid: string,
    summaryType: SummaryTypesEnum,
    contentState: ContentStatesEnum,
    chapterUid: string
  ) {
    this.title = summary.title;
    this.description = summary.description;
    this.collectionUid = collectionUid;
    this.chapterUid = chapterUid;
    this.summaryType = summaryType;
    this.contentState = contentState;
    this.contentType = ContentTypesEnum.SUMMARY;

    if (summary.media && summary.media.length > 0) {
      this.media = MapResponseToModel(summary.media, MediaModel);
    }
  }
}
